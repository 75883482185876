'use client';
import * as React from 'react';
import { Exo_2, Onest } from 'next/font/google';
import Image from 'next/image';
import { useState } from 'react';
import { X, MenuIcon } from 'lucide-react';
import { Button } from './ui/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { ArrowUpRight, PanelTopIcon, Share2 } from 'lucide-react';
import { HoveredLink, Menu, MenuItem } from './ui/navbar-menu';

const exo2 = Exo_2({ weight: ['300', '400', '700'], subsets: ['latin'] });
const headFont = Onest({
  weight: ['400'],
  subsets: ['latin'],
});

const Navbar: React.FunctionComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <NavbarMenu className="top-4 hidden sm:block px-4" />

      <nav
        className={cn(
          headFont.className,
          'flex w-full itmes-center justify-between sm:hidden px-4 py-2 border-b border-primary fixed top-0 z-50 backdrop-blur-lg'
        )}
      >
        <Link
          href={`/`}
          className="hover:opacity-80 text-3xl self-center"
          aria-label="homepage"
        >
          <div className="flex items-center gap-2">
            <Image
              src={'/logo-enhanced.svg'}
              alt={'fashn-logo'}
              width={'30'}
              height={'30'}
            />
            <h2 className={cn(exo2.className, 'font-semibold text-2xl')}>
              FASHN<span className="font-light">AI</span>
            </h2>
          </div>
        </Link>
        <Button size={'icon'} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {' '}
          {isMenuOpen ? (
            <X size={20} aria-label="close-nav-menu" />
          ) : (
            <MenuIcon size={20} aria-label="nav-menu" />
          )}
        </Button>
        <div
          className={`${isMenuOpen ? 'block' : 'hidden'
            } absolute p-6 mt-[48px] right-0 w-full xl:relative xl:w-auto xl:p-0 xl:mt-0 xl:flex bg-background border-b border-primary`}
        >
          <ul
            className={`flex flex-col items-center justify-center gap-4 opacity-80`}
          >
            <li>
              <Link href={'/products/api'} aria-label="api">
                API
              </Link>
            </li>
            <li>
              <Link href={'/products/web-app'} aria-label="web-app">
                Web App
              </Link>
            </li>
            <li>
              <Link href="/blog" onClick={handleLinkClick} aria-label="blog">
                Blog
              </Link>
            </li>
            <li>
              <Link href="/faq" onClick={handleLinkClick} aria-label="blog">
                FAQ
              </Link>
            </li>
            <li>
              <a href="https://docs.fashn.ai" aria-label="docs" target="_blank">
                Docs
              </a>
            </li>
            <li>
              <Link href="/affiliates" onClick={handleLinkClick} aria-label="Affiliates">
                Affiliates
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

function NavbarMenu({ className }: { className?: string }) {
  const [active, setActive] = useState<string | null>(null);
  return (
    <div
      className={cn(
        'fixed top-10 inset-x-0 max-w-4xl mx-auto z-50',
        className,
        headFont.className
      )}
    >
      <Menu setActive={setActive}>
        <div className="flex items-center justify-between w-full">
          <ul className="flex items-center space-x-6">
            <Link
              href={`/`}
              className="hover:opacity-80 text-3xl self-center"
              aria-label="homepage"
            >
              <div className="flex items-center gap-2">
                <Image
                  src={'/logo-enhanced.svg'}
                  alt={'fashn-logo'}
                  width={'30'}
                  height={'30'}
                />
                <h2 className={cn(exo2.className, 'font-semibold text-2xl')}>
                  FASHN<span className="font-light">AI</span>
                </h2>
              </div>
            </Link>
            <MenuItem setActive={setActive} active={active} item="Products">
              <div className="flex flex-col space-y-4 text-sm">
                <HoveredLink href="/products/api">
                  <div className="p-2 bg-gradient-to-br from-primary border border-primary to-background w-fit rounded-lg">
                    <Share2 size={18} />
                  </div>{' '}
                  API
                </HoveredLink>
                <HoveredLink href="/products/web-app">
                  <div className="p-2 bg-gradient-to-br from-primary border border-primary to-background w-fit rounded-lg">
                    <PanelTopIcon size={18} />
                  </div>
                  Web App
                </HoveredLink>
              </div>
            </MenuItem>
            <Link href={'/blog'}>
              <MenuItem
                setActive={setActive}
                active={null}
                item="Blog"
              ></MenuItem>
            </Link>
            <Link href={'/faq'}>
              <MenuItem
                setActive={setActive}
                active={null}
                item="FAQ"
              ></MenuItem>
            </Link>
            <Link href={'/affiliates'}>
              <MenuItem
                setActive={setActive}
                active={null}
                item="Affiliates"
              ></MenuItem>
            </Link>
          </ul>

          <ul className="flex items-center space-x-2">
            <a href="https://docs.fashn.ai" target="_blank">
              <Button
                variant={'outline'}
                size={'sm'}
                className="flex items-center gap-1"
              >
                API Docs <ArrowUpRight size={16} />
              </Button>
            </a>
            <a href={'https://app.fashn.ai'} target='_blank'>
              <Button variant={'special'} size={'sm'}>
                Log In
              </Button>
            </a>
          </ul>
        </div>
      </Menu>
    </div>
  );
}
